@import "../../../resources/styles/fonts";

.cell {
    margin-left: 1rem;
    margin-right: 1rem;
    height: 100%;
    
    input {
        .monospace;
        font-size: @monospace-font-size;
    }
}
