@import "../../../../resources/styles/colors";

.status {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 0.125rem;
  font-size: 1.25rem;
  color: @whiteConstant;
  transition: background-color linear 1s;
  
}

.small {
  width: 1.25rem;
  height: 1.25rem;
  font-size: 0.875rem;
}

.vNext {
  border-radius: 0.25rem;
  height: 2rem;
  width: 2rem;
}

.Queued { background-color: @infoConstant; }
.Executing { background-color: @infoConstant; }
.Paused { background-color: @alertConstant; }
.TimedOut { background-color: @alertConstant; }
.Failed { background-color: @dangerConstant; }
.Canceled { background-color: @darkBackground; color: @darkText;}
.Cancelling { background-color: @darkBackground; color: @darkText;}
.Success { background-color: @successConstant; }
.SuccessWithWarnings { background-color: @successConstant; }
