@import "../../../../../resources/styles/colors";
@import "../../../../../resources/styles/fonts";
@import "../../../../../resources/styles/media";

.cardHeaderActions {
  right: 3rem;
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  display: flex;
  align-items: center;
}


.taskActions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  color: @primaryText !important;

  *:first-child {
    gap: 0.5rem;
  }

  a, :global(.MuiButton-root) {
    font-size: 0.875rem;
    text-transform: none;
    font-weight: normal;
    color: @primaryText !important;
    border-radius: 6px;
  }

  :global(.MuiIconButton-root) {
    padding: 6px;
    color: @primaryText !important;
    border-radius: 6px;
  }

  :global(.MuiIconButton-root):hover {
    background-color: var(--colorButtonBackgroundQuietHover);
  }

  a span,
  :global(.MuiButton-iconSizeMedium) > *:first-child {
    font-size: 16px;
    margin-right: 10px;
  }
}

.filters {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 0.5rem;

  @media (max-width: @screen-sm) {
    flex-direction: column;
    align-items: flex-start;
  }

  :global(.MuiSelect-root) {
    font-size: 0.875rem;
    padding-left: 16px;
    border-radius: 6px;
  }

  :global(.MuiInput-underline:before) {
    border-bottom: none;
  }
  :global(.MuiInput-underline:after) {
    border-bottom: none;
  }
  :global(.MuiInput-underline:hover:not(.Mui-disabled):before) {
    border-bottom: none;
  }

  :global(.MuiSelect-root):hover {
    background-color: var(--colorButtonBackgroundQuietHover);
    border-bottom: none;
  }
}

.settingsPopover {
  padding: 10px 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 0.8125rem;
}

.settingsSwitch {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.taskLogHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.filterActionContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap; // Need this to wrap for mobile.
}
