@import "../../resources/styles/colors";
@import "../../resources/styles/fonts";
@import "../../resources/styles/variables";
@import "../../resources/styles/media";
@import "../../resources/styles/text";

.topLevelBlock {
  margin: 1rem;
}

.block {
  border: none;
  margin: 0 0.5rem;
  font-size: 0.875rem;
  overflow: hidden;
}

.lines {
  .monospace;
  font-size: 0.75rem;
  margin: 0 1rem 1rem 0.3rem; // Left side is offset already by .body.
  word-wrap: break-word;
  line-height: 1.3rem;
  overflow-wrap: break-word;
}

.currentCommandExecuting{
  margin-top: -1rem;
  padding-left: 4.4rem;
  color: @infoText;
  font-size: 1rem;
  cursor: pointer;
  display: block;
}

.blockHeader {
  font-weight: @normal;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color linear 0.2s;
  animation: fadeIn 0.2s;

  .stepEditorLink {
    margin-left: 1rem;
    font-size: 0.8rem;
    visibility: hidden;
    transition: all 0.1s linear;
    display: inline-block;
  }
  &:hover {
    .stepEditorLink {
      transform: translateX(5px);
      visibility: visible;
    }
  }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.blockExpanderIcon {
  margin: 0.5rem 0.7rem 0.5rem 0.2rem;
  width: 0.5rem;
  display: inline-block;

  :global(.fa-solid) {
    transition: transform .2s ease, opacity .2s;
    color: @primaryText;
    opacity: 0.5;
  }
  
  &.blockExpanderIconExpanded :global(.fa-solid) {
    transform: rotate(90deg);
    opacity: 1;
  }
}

.blockStatusIcon {
  padding: 8px 0;
  font-size: 1rem;
  display: inline-block;
  vertical-align: middle;
  color: @primaryText;
  white-space: nowrap;
}

.blockTitle {
  white-space: pre-wrap;
  word-break: break-word;
  margin-right: 1rem;
  cursor: pointer;
  color: @primaryText;
  display: flex;
  flex-direction: row;
  align-items: center;
  
  &:hover {
    color: @primaryText;
  }
  .blockTitleName {
    padding: 0 0.5rem;
    display: inline-block;
  }
}

.blockTime {
  .noUserSelect;
  .monospace;
  
  margin-left: auto;
  text-align: right;
  font-size: 0.75rem;
  color: @secondaryText;
  position: relative;
  
  @media (min-width: @screen-sm) {
      min-width: 117px;
  }
}

.blockTimeImpact {
  position: absolute;
  background: @primaryLight;
  right: 0;
  border-radius: 1px;
  display: inline-block;
  bottom: -4px;
  height: 4px;
}

.body {
  min-height: 0.5rem;
  margin-left: 1.5rem;
}

.progress {
  font-size: 0.7rem;
}
