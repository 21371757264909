@import "../../../resources/styles/colors";
@import "../../../resources/styles/fonts";
@import "../../../resources/styles/variables";

.deletedContent {
    color: @secondaryText !important;
    text-decoration: line-through
}

.nameCell {
    height: 100%;
    overflow: hidden;
    font-weight: @bold;
}

.nameCellContentReadonly {
    height: 100%;
    display: flex;
    align-items: center;
}

.inputContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    input {
        .monospace;
        font-size: @monospace-font-size;
    }
}

